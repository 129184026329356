<template>
    <div>

        <!-- Banner -->
        <app-banner pageName="Nurse Aide Training Services"></app-banner>
        <!-- End Banner -->

        <!-- Page Content -->
        <div id="main_area">
            <div class="wrapper">
                <main>

                    <!--  -->
                    <div class="breadcrumbs">
                        <span property="itemListElement" typeof="ListItem">
                            <router-link :to="{ name: 'Home' }" tag="a" property="item" typeof="WebPage" title="Go to Home." class="home">
                                <span property="name">Home</span>
                            </router-link>
                            <meta property="position" content="1">
                        </span> &gt;
                        <span property="name" class="post post-page current-item">Services</span>
                        <!-- <span class="post post-page current-item">Nurse Aide Training Services</span> -->
                    </div>

                    <!--  -->
                    <div id="post-50" class="post-50 page type-page status-publish hentry">
                        <div class="entry-content">
                            <div class=intro_txt>We believe that patience and understanding makes a world of difference.</div>
                            <p>We are dedicated to helping you keep your dignity and independence for as long as possible. We assess your condition and develop a personalized care plan to ensure that you get the care that you deserve. We can provide assistance as you transition from hospitalization to companionship as you recover from an injury, and other geriatric care services in PA, making sure that the level of care you receive is tailored to your needs.</p>

                            <!-- FORM HERE -->
                            <div class="clearfix">
                                <div class="wrapper">
                                    <div id="contact_us_form_1" class="template_form">
                                        <div class="form_frame_b">
                                            <div class="form_content">
                                                <div id="submitform" name="contact">

                                                    <alert :alertData="alertData"/>

                                                    <span class="required-info">* Required Information</span>

                                                    <!-- PROGRESS -->
                                                    <div class="progress-bar" style="margin-bottom: 60px;">
                                                        <div class="progress" id="progress"></div>
                                                        <div class="progress-step" :class="{ 'active': ['position', 'military', 'education', 'employment'].includes(type) }" data-title="Position Specific Information"></div>
                                                        <div class="progress-step" :class="{ 'active': ['military', 'education', 'employment'].includes(type) }" data-title="Military Service"></div>
                                                        <div class="progress-step" :class="{ 'active': ['education', 'employment'].includes(type) }" data-title="Education"></div>
                                                        <div class="progress-step" :class="{ 'active': ['employment'].includes(type) }" data-title="Previous Employment"></div>
                                                    </div>

                                                    <!-- Position Specific Information -->
                                                    <div v-if="type === 'position'" class="main fieldbox">
                                                        <div class="fieldbox">
                                                            <div class="fieldheader">
                                                                <p>
                                                                    <i class="fa fa-info-circle"></i> Position Specific Information
                                                                </p>
                                                            </div>
                                                            <div class="fieldcontent">

                                                                <div class="form_box_col2">
                                                                    <div class="group">
                                                                        <div class="form_label">
                                                                            <label class="text_uppercase">
                                                                                <strong>Position Applying For
                                                                                    <span class="required_filed">*</span>
                                                                                </strong>
                                                                            </label>
                                                                        </div>
                                                                        <div class="select-field field_holder animated_box">
                                                                            <select
                                                                                autocomplete="nope"
                                                                                name="Position_Applying_For"
                                                                                class="form_field"
                                                                                id="Position_Applying_For"
                                                                                v-model="$v.applicant.position.$model"
                                                                                placeholder="Enter position applying for here"
                                                                                :class="{ 'error': $v.applicant.position.$error }"
                                                                            >
                                                                                <option value="">- Please select -</option>
                                                                                <option value="Home Health Aide">Home Health Aide</option>
                                                                                <option value="Office Position">Office Position</option>
                                                                                <option value="Other">Other</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>

                                                                    <div class="group">
                                                                        <div class="form_label">
                                                                            <label class="text_uppercase">
                                                                                <strong>Date Available to Start
                                                                                    <span class="required_filed">*</span>
                                                                                </strong>
                                                                            </label>
                                                                        </div>
                                                                        <div class="field_holder animated_box">
                                                                            <datepicker
                                                                                v-model="$v.applicant.availableDate.$model"
                                                                                :class="{ 'error': $v.applicant.availableDate.$error }"
                                                                                format="MM/dd/yyyy"
                                                                                class="form_field Date"
                                                                                :disabled-dates="disabledDates"
                                                                                id="Preferred_Date"
                                                                                placeholder="Enter preferred date here"
                                                                            />
                                                                            <span class="animated_class"></span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="form_box_col2">
                                                                    <div class="form_box_col2">

                                                                        <!-- Do you have a Professional License -->
                                                                        <template>
                                                                            <div class="group">
                                                                                <div class="form_label">
                                                                                    <label class="text_uppercase">
                                                                                        <strong>Do you have a Professional License
                                                                                            <span class="required_filed">*</span>
                                                                                        </strong>
                                                                                    </label>
                                                                                </div>
                                                                                <table class="radio" border="0" cellpadding="0" cellspacing="0">
                                                                                    <tr>
                                                                                        <td style="width: 50%!important;">
                                                                                            <input
                                                                                                autocomplete="nope"
                                                                                                type="radio"
                                                                                                :class="{ 'form_field error': $v.applicant.positionInfo.haveProfessionalLicense.$error }"
                                                                                                v-model="$v.applicant.positionInfo.haveProfessionalLicense.$model"
                                                                                                name="have_professional_license"
                                                                                                :value="true"
                                                                                                id="have_professional_license_yes"
                                                                                            />
                                                                                            <label for="have_professional_license_yes" style="font-weight:normal; color:#000;">YES</label>
                                                                                        </td>
                                                                                        <td style="width: 50%!important;">
                                                                                            <input
                                                                                                autocomplete="nope"
                                                                                                type="radio"
                                                                                                :class="{ 'form_field error': $v.applicant.positionInfo.haveProfessionalLicense.$error }"
                                                                                                v-model="$v.applicant.positionInfo.haveProfessionalLicense.$model"
                                                                                                name="have_professional_license_no"
                                                                                                :value="false"
                                                                                                id="have_professional_license_no"
                                                                                            >
                                                                                            <label for="have_professional_license_no" style="font-weight:normal; color:#000;">NO</label>
                                                                                        </td>
                                                                                    </tr>
                                                                                </table>
                                                                            </div>

                                                                            <div class="group" v-if="applicant.positionInfo.haveProfessionalLicense">
                                                                                <table class="radio no-border" border="0" cellpadding="0" cellspacing="0">
                                                                                    <tr>
                                                                                        <td style="width: 50%!important;">
                                                                                            <div class="form_label">
                                                                                                <label class="text_uppercase">
                                                                                                    <strong>Type
                                                                                                        <span class="required_filed">*</span>
                                                                                                    </strong>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div class="field_holder animated_box">
                                                                                                <input
                                                                                                    type="text"
                                                                                                    v-model="$v.applicant.positionInfo.licenseType.$model"
                                                                                                    name="licenseType"
                                                                                                    class="form_field"
                                                                                                    autocomplete="nope"
                                                                                                    placeholder="License Type"
                                                                                                    :class="{ 'error': $v.applicant.positionInfo.licenseType.$error }"
                                                                                                />
                                                                                                <span class="animated_class"></span>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td style="width: 50%!important;">
                                                                                            <div class="form_label">
                                                                                                <label class="text_uppercase">
                                                                                                    <strong>License #
                                                                                                        <span class="required_filed">*</span>
                                                                                                    </strong>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div class="field_holder animated_box">
                                                                                                <input
                                                                                                    type="text"
                                                                                                    v-model="$v.applicant.positionInfo.licenseNumber.$model"
                                                                                                    name="licenseNumber"
                                                                                                    class="form_field"
                                                                                                    autocomplete="nope"
                                                                                                    placeholder="License Number"
                                                                                                    :class="{ 'error': $v.applicant.positionInfo.licenseNumber.$error }"
                                                                                                />
                                                                                                <span class="animated_class"></span>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                </table>
                                                                            </div>
                                                                        </template>

                                                                        <!-- Has your license ever been suspended or revoked? -->
                                                                        <template>
                                                                            <div class="group">
                                                                                <div class="form_label">
                                                                                    <label class="text_uppercase">
                                                                                        <strong>Has your license ever been suspended or revoked?
                                                                                            <span class="required_filed">*</span>
                                                                                        </strong>
                                                                                    </label>
                                                                                </div>
                                                                                <table class="radio" border="0" cellpadding="0" cellspacing="0">
                                                                                    <tr>
                                                                                        <td style="width: 50%!important;">
                                                                                            <input
                                                                                                autocomplete="nope"
                                                                                                type="radio"
                                                                                                :class="{ 'form_field error': $v.applicant.positionInfo.licenseIsSuspended.$error }"
                                                                                                v-model="$v.applicant.positionInfo.licenseIsSuspended.$model"
                                                                                                name="license_is_suspended"
                                                                                                :value="true"
                                                                                                id="license_is_suspended_yes"
                                                                                            />
                                                                                            <label for="license_is_suspended_yes" style="font-weight:normal; color:#000;">YES</label>
                                                                                        </td>
                                                                                        <td style="width: 50%!important;">
                                                                                            <input
                                                                                                autocomplete="nope"
                                                                                                type="radio"
                                                                                                :class="{ 'form_field error': $v.applicant.positionInfo.licenseIsSuspended.$error }"
                                                                                                v-model="$v.applicant.positionInfo.licenseIsSuspended.$model"
                                                                                                name="license_is_suspended_no"
                                                                                                :value="false"
                                                                                                id="license_is_suspended_no"
                                                                                            >
                                                                                            <label for="license_is_suspended_no" style="font-weight:normal; color:#000;">NO</label>
                                                                                        </td>
                                                                                    </tr>
                                                                                </table>
                                                                            </div>

                                                                            <div class="group" v-if="applicant.positionInfo.licenseIsSuspended">
                                                                                <table class="radio no-border" border="0" cellpadding="0" cellspacing="0">
                                                                                    <tr>
                                                                                        <td style="width: 100%!important;">
                                                                                            <div class="form_label">
                                                                                                <label class="text_uppercase">
                                                                                                    <strong>Suspend or Revoke Reason
                                                                                                        <span class="required_filed">*</span>
                                                                                                    </strong>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div class="field_holder animated_box">
                                                                                                <input
                                                                                                    type="text"
                                                                                                    v-model="$v.applicant.positionInfo.suspendReason.$model"
                                                                                                    name="suspendReason"
                                                                                                    class="form_field"
                                                                                                    autocomplete="nope"
                                                                                                    placeholder="Suspend or Revoke Reason"
                                                                                                    :class="{ 'error': $v.applicant.positionInfo.suspendReason.$error }"
                                                                                                />
                                                                                                <span class="animated_class"></span>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                </table>
                                                                            </div>
                                                                        </template>

                                                                        <!-- Are you a citizen of the United States? -->
                                                                        <template>
                                                                            <div class="group">
                                                                                <div class="form_label">
                                                                                    <label class="text_uppercase">
                                                                                        <strong>Are you a citizen of the United States?
                                                                                            <span class="required_filed">*</span>
                                                                                        </strong>
                                                                                    </label>
                                                                                </div>
                                                                                <table class="radio" border="0" cellpadding="0" cellspacing="0">
                                                                                    <tr>
                                                                                        <td style="width: 50%!important;">
                                                                                            <input
                                                                                                autocomplete="nope"
                                                                                                type="radio"
                                                                                                :class="{ 'form_field error': $v.applicant.positionInfo.isUSCitizen.$error }"
                                                                                                v-model="$v.applicant.positionInfo.isUSCitizen.$model"
                                                                                                name="is_us_citizen"
                                                                                                :value="true"
                                                                                                id="is_us_citizen_yes"
                                                                                            />
                                                                                            <label for="is_us_citizen_yes" style="font-weight:normal; color:#000;">YES</label>
                                                                                        </td>
                                                                                        <td style="width: 50%!important;">
                                                                                            <input
                                                                                                autocomplete="nope"
                                                                                                type="radio"
                                                                                                :class="{ 'form_field error': $v.applicant.positionInfo.isUSCitizen.$error }"
                                                                                                v-model="$v.applicant.positionInfo.isUSCitizen.$model"
                                                                                                name="is_us_citizen_no"
                                                                                                :value="false"
                                                                                                id="is_us_citizen_no"
                                                                                            >
                                                                                            <label for="is_us_citizen_no" style="font-weight:normal; color:#000;">NO</label>
                                                                                        </td>
                                                                                    </tr>
                                                                                </table>
                                                                            </div>

                                                                            <div class="group" v-if="applicant.positionInfo.isUSCitizen === false">
                                                                                <div class="form_label">
                                                                                    <label class="text_uppercase">
                                                                                        <strong>are you authorized to work in the U.S.?
                                                                                            <span class="required_filed">*</span>
                                                                                        </strong>
                                                                                    </label>
                                                                                </div>
                                                                                <table class="radio" border="0" cellpadding="0" cellspacing="0">
                                                                                    <tr>
                                                                                        <td style="width: 50%!important;">
                                                                                            <input
                                                                                                autocomplete="nope"
                                                                                                type="radio"
                                                                                                :class="{ 'form_field error': $v.applicant.positionInfo.isUSAuthorized.$error }"
                                                                                                v-model="$v.applicant.positionInfo.isUSAuthorized.$model"
                                                                                                name="us_authorized"
                                                                                                :value="true"
                                                                                                id="us_authorized_yes"
                                                                                            />
                                                                                            <label for="us_authorized_yes" style="font-weight:normal; color:#000;">YES</label>
                                                                                        </td>
                                                                                        <td style="width: 50%!important;">
                                                                                            <input
                                                                                                autocomplete="nope"
                                                                                                type="radio"
                                                                                                :class="{ 'form_field error': $v.applicant.positionInfo.isUSAuthorized.$error }"
                                                                                                v-model="$v.applicant.positionInfo.isUSAuthorized.$model"
                                                                                                name="us_authorized_no"
                                                                                                :value="false"
                                                                                                id="us_authorized_no"
                                                                                            >
                                                                                            <label for="us_authorized_no" style="font-weight:normal; color:#000;">NO</label>
                                                                                        </td>
                                                                                    </tr>
                                                                                </table>
                                                                            </div>
                                                                        </template>

                                                                        <!-- Have you ever worked for this company? -->
                                                                        <template>
                                                                            <div class="group">
                                                                                <div class="form_label">
                                                                                    <label class="text_uppercase">
                                                                                        <strong>Have you ever worked for this company?
                                                                                            <span class="required_filed">*</span>
                                                                                        </strong>
                                                                                    </label>
                                                                                </div>
                                                                                <table class="radio" border="0" cellpadding="0" cellspacing="0">
                                                                                    <tr>
                                                                                        <td style="width: 50%!important;">
                                                                                            <input
                                                                                                autocomplete="nope"
                                                                                                type="radio"
                                                                                                :class="{ 'form_field error': $v.applicant.positionInfo.isWorkedBefore.$error }"
                                                                                                v-model="$v.applicant.positionInfo.isWorkedBefore.$model"
                                                                                                name="is_worked_before"
                                                                                                :value="true"
                                                                                                id="is_worked_before_yes"
                                                                                            />
                                                                                            <label for="is_worked_before_yes" style="font-weight:normal; color:#000;">YES</label>
                                                                                        </td>
                                                                                        <td style="width: 50%!important;">
                                                                                            <input
                                                                                                autocomplete="nope"
                                                                                                type="radio"
                                                                                                :class="{ 'form_field error': $v.applicant.positionInfo.isWorkedBefore.$error }"
                                                                                                v-model="$v.applicant.positionInfo.isWorkedBefore.$model"
                                                                                                name="is_worked_before_no"
                                                                                                :value="false"
                                                                                                id="is_worked_before_no"
                                                                                            >
                                                                                            <label for="is_worked_before_no" style="font-weight:normal; color:#000;">NO</label>
                                                                                        </td>
                                                                                    </tr>
                                                                                </table>
                                                                            </div>

                                                                            <div class="group" v-if="applicant.positionInfo.isWorkedBefore">
                                                                                <table class="radio no-border" border="0" cellpadding="0" cellspacing="0">
                                                                                    <tr>
                                                                                        <td style="width: 100%!important;">
                                                                                            <div class="form_label">
                                                                                                <label class="text_uppercase">
                                                                                                    <strong>When did you previously work for this company?
                                                                                                        <span class="required_filed">*</span>
                                                                                                    </strong>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div class="field_holder animated_box">
                                                                                                <datepicker
                                                                                                    v-model="$v.applicant.positionInfo.workedDate.$model"
                                                                                                    :class="{ 'error': $v.applicant.positionInfo.workedDate.$error }"
                                                                                                    format="MM/dd/yyyy"
                                                                                                    class="form_field Date"
                                                                                                    placeholder="When did you previously work for this company?"
                                                                                                />
                                                                                                <span class="animated_class"></span>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                </table>
                                                                            </div>
                                                                        </template>

                                                                        <!-- Have you ever had a felony or misdemeanor conviction? -->
                                                                        <template>
                                                                            <div class="group">
                                                                                <div class="form_label">
                                                                                    <label class="text_uppercase">
                                                                                        <strong>Have you ever had a felony or misdemeanor conviction?
                                                                                            <!-- <span class="required_filed">*</span> -->
                                                                                        </strong>
                                                                                    </label>
                                                                                </div>
                                                                                <table class="radio" border="0" cellpadding="0" cellspacing="0">
                                                                                    <tr>
                                                                                        <td style="width: 50%!important;">
                                                                                            <input
                                                                                                autocomplete="nope"
                                                                                                type="radio"
                                                                                                :class="{ 'form_field error': $v.applicant.positionInfo.hasMisdemeanor.$error }"
                                                                                                v-model="$v.applicant.positionInfo.hasMisdemeanor.$model"
                                                                                                name="has_misdemeanor"
                                                                                                :value="true"
                                                                                                id="has_misdemeanor_yes"
                                                                                            />
                                                                                            <label for="has_misdemeanor_yes" style="font-weight:normal; color:#000;">YES</label>
                                                                                        </td>
                                                                                        <td style="width: 50%!important;">
                                                                                            <input
                                                                                                autocomplete="nope"
                                                                                                type="radio"
                                                                                                :class="{ 'form_field error': $v.applicant.positionInfo.hasMisdemeanor.$error }"
                                                                                                v-model="$v.applicant.positionInfo.hasMisdemeanor.$model"
                                                                                                name="has_misdemeanor_no"
                                                                                                :value="false"
                                                                                                id="has_misdemeanor_no"
                                                                                            >
                                                                                            <label for="has_misdemeanor_no" style="font-weight:normal; color:#000;">NO</label>
                                                                                        </td>
                                                                                    </tr>
                                                                                </table>
                                                                            </div>

                                                                            <div class="group" v-if="applicant.positionInfo.hasMisdemeanor">
                                                                                <table class="radio no-border" border="0" cellpadding="0" cellspacing="0">
                                                                                    <tr>
                                                                                        <td style="width: 100%!important;">
                                                                                            <div class="form_label">
                                                                                                <label class="text_uppercase">
                                                                                                    <strong>felony or misdemeanor conviction details
                                                                                                        <span class="required_filed">*</span>
                                                                                                    </strong>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div class="field_holder animated_box">
                                                                                                <input
                                                                                                    type="text"
                                                                                                    v-model="$v.applicant.positionInfo.misdemeanorDetails.$model"
                                                                                                    name="misdemeanorDetails"
                                                                                                    class="form_field"
                                                                                                    autocomplete="nope"
                                                                                                    placeholder="Felony or misdemeanor conviction details"
                                                                                                    :class="{ 'error': $v.applicant.positionInfo.misdemeanorDetails.$error }"
                                                                                                />
                                                                                                <span class="animated_class"></span>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                </table>
                                                                            </div>
                                                                        </template>

                                                                        <!-- Languages Spoken -->
                                                                        <template>
                                                                            <div class="group">
                                                                                <div class="form_label">
                                                                                    <label class="text_uppercase">
                                                                                        <strong>Languages Spoken
                                                                                            <!-- <span class="required_filed">*</span> -->
                                                                                        </strong>
                                                                                    </label>
                                                                                </div>
                                                                                <table class="radio" border="0" cellpadding="0" cellspacing="0">
                                                                                    <tr>
                                                                                        <td style="width: 25%!important;">
                                                                                            <input
                                                                                                autocomplete="nope"
                                                                                                type="checkbox"
                                                                                                :class="{ 'form_field error': $v.applicant.positionInfo.languages.$error }"
                                                                                                v-model="$v.applicant.positionInfo.languages.$model"
                                                                                                name="english"
                                                                                                value="english"
                                                                                                id="english"
                                                                                            />
                                                                                            <label for="english" style="font-weight:normal; color:#000;">English</label>
                                                                                        </td>
                                                                                        <td style="width: 25%!important;">
                                                                                            <input
                                                                                                autocomplete="nope"
                                                                                                type="checkbox"
                                                                                                :class="{ 'form_field error': $v.applicant.positionInfo.languages.$error }"
                                                                                                v-model="$v.applicant.positionInfo.languages.$model"
                                                                                                name="spanish"
                                                                                                value="spanish"
                                                                                                id="spanish"
                                                                                            >
                                                                                            <label for="spanish" style="font-weight:normal; color:#000;">Spanish</label>
                                                                                        </td>
                                                                                        <td style="width: 25%!important;">
                                                                                            <input
                                                                                                autocomplete="nope"
                                                                                                type="checkbox"
                                                                                                :class="{ 'form_field error': $v.applicant.positionInfo.languages.$error }"
                                                                                                v-model="$v.applicant.positionInfo.languages.$model"
                                                                                                name="french"
                                                                                                value="french"
                                                                                                id="french"
                                                                                            >
                                                                                            <label for="french" style="font-weight:normal; color:#000;">French</label>
                                                                                        </td>
                                                                                        <td style="width: 25%!important;">
                                                                                            <input
                                                                                                autocomplete="nope"
                                                                                                type="checkbox"
                                                                                                :class="{ 'form_field error': $v.applicant.positionInfo.languages.$error }"
                                                                                                v-model="$v.applicant.positionInfo.languages.$model"
                                                                                                name="other"
                                                                                                value="other"
                                                                                                id="other"
                                                                                            >
                                                                                            <label for="other" style="font-weight:normal; color:#000;">Other</label>
                                                                                        </td>
                                                                                    </tr>
                                                                                </table>
                                                                            </div>
                                                                        </template>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- Military Service -->
                                                    <div v-else-if="type === 'military'" class="main fieldbox">
                                                        <div class="fieldbox">
                                                            <div class="fieldheader">
                                                                <p>
                                                                    <i class="fa fa-info-circle"></i> Military Service
                                                                </p>
                                                            </div>
                                                            <div class="fieldcontent">

                                                                <div class="form_box_col2">
                                                                    <div class="form_box_col2">

                                                                        <!-- Branch -->
                                                                        <template>
                                                                            <div class="group">
                                                                                <div class="form_label">
                                                                                    <label class="text_uppercase">
                                                                                        <strong>Branch
                                                                                            <!-- <span class="required_filed">*</span> -->
                                                                                        </strong>
                                                                                    </label>
                                                                                </div>
                                                                                <div class="field_holder animated_box">
                                                                                    <input
                                                                                        autocomplete="nope"
                                                                                        type="text"
                                                                                        :class="{ 'error': $v.applicant.militaryInfo.branch.$error }"
                                                                                        v-model="$v.applicant.militaryInfo.branch.$model"
                                                                                        class="form_field"
                                                                                        placeholder="Branch"
                                                                                    />
                                                                                    <span class="animated_class"></span>
                                                                                </div>
                                                                            </div>

                                                                            <div class="group">
                                                                                <table class="radio no-border" border="0" cellpadding="0" cellspacing="0">
                                                                                    <tr>
                                                                                        <td style="width: 50%!important;">
                                                                                            <div class="form_label">
                                                                                                <label class="text_uppercase">
                                                                                                    <strong>From
                                                                                                        <!-- <span class="required_filed">*</span> -->
                                                                                                    </strong>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div class="field_holder animated_box">
                                                                                                <datepicker
                                                                                                    format="MM/dd/yyyy"
                                                                                                    class="form_field Date"
                                                                                                    :class="{ 'error': $v.applicant.militaryInfo.from.$error }"
                                                                                                    v-model="$v.applicant.militaryInfo.from.$model"
                                                                                                    placeholder="From"
                                                                                                />
                                                                                                <span class="animated_class"></span>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td style="width: 50%!important;">
                                                                                            <div class="form_label">
                                                                                                <label class="text_uppercase">
                                                                                                    <strong>To
                                                                                                        <!-- <span class="required_filed">*</span> -->
                                                                                                    </strong>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div class="field_holder animated_box">
                                                                                                <datepicker
                                                                                                    format="MM/dd/yyyy"
                                                                                                    class="form_field Date"
                                                                                                    :class="{ 'error': $v.applicant.militaryInfo.to.$error }"
                                                                                                    v-model="$v.applicant.militaryInfo.to.$model"
                                                                                                    placeholder="To"
                                                                                                />
                                                                                                <span class="animated_class"></span>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                </table>
                                                                            </div>
                                                                        </template>

                                                                        <!-- Rank at Discharge -->
                                                                        <template>
                                                                            <div class="group">
                                                                                <div class="form_label">
                                                                                    <label class="text_uppercase">
                                                                                        <strong>Rank at Discharge
                                                                                            <!-- <span class="required_filed">*</span> -->
                                                                                        </strong>
                                                                                    </label>
                                                                                </div>
                                                                                <div class="field_holder animated_box">
                                                                                    <input
                                                                                        autocomplete="nope"
                                                                                        type="text"
                                                                                        :class="{ 'error': $v.applicant.militaryInfo.dischargeRank.$error }"
                                                                                        v-model="$v.applicant.militaryInfo.dischargeRank.$model"
                                                                                        class="form_field"
                                                                                        placeholder="Rank at Discharge"
                                                                                    />
                                                                                    <span class="animated_class"></span>
                                                                                </div>
                                                                            </div>

                                                                            <div class="group">
                                                                                <div class="form_label">
                                                                                    <label class="text_uppercase">
                                                                                        <strong>Type of Discharge
                                                                                            <!-- <span class="required_filed">*</span> -->
                                                                                        </strong>
                                                                                    </label>
                                                                                </div>
                                                                                <div class="field_holder animated_box">
                                                                                    <input
                                                                                        autocomplete="nope"
                                                                                        type="text"
                                                                                        :class="{ 'error': $v.applicant.militaryInfo.dischargeType.$error }"
                                                                                        v-model="$v.applicant.militaryInfo.dischargeType.$model"
                                                                                        class="form_field"
                                                                                        placeholder="Type of Discharge"
                                                                                    />
                                                                                    <span class="animated_class"></span>
                                                                                </div>
                                                                            </div>
                                                                        </template>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- Education -->
                                                    <div v-else-if="type === 'education'" class="main fieldbox">
                                                        <div class="fieldbox">
                                                            <div class="fieldheader">
                                                                <p>
                                                                    <i class="fa fa-info-circle"></i> Education
                                                                </p>
                                                            </div>
                                                            <div class="fieldcontent">

                                                                <!-- HIGH SCHOOL -->
                                                                <template v-for="(education, index) in $v.applicant.educationInfo.$each.$iter">
                                                                    <div class="form_box" :key="`education-${index}`">
                                                                        <div class="form_box_col2">
                                                                            <div class="group">
                                                                                <div class="form_label">
                                                                                    <label class="text_uppercase">
                                                                                        <strong>{{ education.name.$model }}
                                                                                            <span class="required_filed">*</span>
                                                                                        </strong>
                                                                                    </label>
                                                                                </div>
                                                                                <div class="field_holder animated_box">
                                                                                    <input
                                                                                        autocomplete="nope"
                                                                                        type="text"
                                                                                        class="form_field"
                                                                                        :class="{ 'error': education.name.$error }"
                                                                                        v-model="education.name.$model"
                                                                                        :placeholder="education.name.$model"
                                                                                    />
                                                                                    <span class="animated_class"></span>
                                                                                </div>
                                                                            </div>

                                                                            <div class="group">
                                                                                <div class="form_label">
                                                                                    <label class="text_uppercase">
                                                                                        <strong>Address
                                                                                            <span class="required_filed">*</span>
                                                                                        </strong>
                                                                                    </label>
                                                                                </div>
                                                                                <div class="field_holder animated_box">
                                                                                    <input
                                                                                        autocomplete="nope"
                                                                                        type="text"
                                                                                        class="form_field"
                                                                                        :class="{ 'error': education.address.$error }"
                                                                                        v-model="education.address.$model"
                                                                                        placeholder="Address"
                                                                                    />
                                                                                    <span class="animated_class"></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="form_box_col3">
                                                                            <div class="group">
                                                                                <table class="radio no-border" border="0" cellpadding="0" cellspacing="0">
                                                                                    <tr>
                                                                                        <td style="width: 50%!important;">
                                                                                            <div class="form_label">
                                                                                                <label class="text_uppercase">
                                                                                                    <strong>From
                                                                                                        <span class="required_filed">*</span>
                                                                                                    </strong>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div class="field_holder animated_box">
                                                                                                <datepicker
                                                                                                    format="MM/dd/yyyy"
                                                                                                    class="form_field Date"
                                                                                                    :class="{ 'error': education.from.$error }"
                                                                                                    v-model="education.from.$model"
                                                                                                    :id="`${index}-education-from`"
                                                                                                    placeholder="From"
                                                                                                />
                                                                                                <span class="animated_class"></span>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td style="width: 50%!important;">
                                                                                            <div class="form_label">
                                                                                                <label class="text_uppercase">
                                                                                                    <strong>To
                                                                                                        <span class="required_filed">*</span>
                                                                                                    </strong>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div class="field_holder animated_box">
                                                                                                <datepicker
                                                                                                    format="MM/dd/yyyy"
                                                                                                    class="form_field Date"
                                                                                                    :class="{ 'error': education.to.$error }"
                                                                                                    v-model="education.to.$model"
                                                                                                    :id="`${index}-education-to`"
                                                                                                    placeholder="To"
                                                                                                />
                                                                                                <span class="animated_class"></span>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                </table>
                                                                            </div>
                                                                            <div class="group">
                                                                                <div class="form_label">
                                                                                    <label class="text_uppercase">
                                                                                        <strong>Did you graduate?
                                                                                            <span class="required_filed">*</span>
                                                                                        </strong>
                                                                                    </label>
                                                                                </div>
                                                                                <table class="radio" border="0" cellpadding="0" cellspacing="0">
                                                                                    <tr>
                                                                                        <td style="width: 50%!important;">
                                                                                            <input
                                                                                                autocomplete="nope"
                                                                                                type="radio"
                                                                                                :class="{ 'form_field error': education.graduated.$error }"
                                                                                                v-model="education.graduated.$model"
                                                                                                :name="`${index}-high_school_graduation`"
                                                                                                :value="true"
                                                                                                :id="`${index}-high_school_graduation_yes`"
                                                                                            />
                                                                                            <label :for="`${index}-high_school_graduation_yes`" style="font-weight:normal; color:#000;">YES</label>
                                                                                        </td>
                                                                                        <td style="width: 50%!important;">
                                                                                            <input
                                                                                                autocomplete="nope"
                                                                                                type="radio"
                                                                                                :class="{ 'form_field error': education.graduated.$error }"
                                                                                                v-model="education.graduated.$model"
                                                                                                :name="`${index}-high_school_graduation_no`"
                                                                                                :value="false"
                                                                                                :id="`${index}-high_school_graduation_no`"
                                                                                            >
                                                                                            <label :for="`${index}-high_school_graduation_no`" style="font-weight:normal; color:#000;">NO</label>
                                                                                        </td>
                                                                                    </tr>
                                                                                </table>
                                                                            </div>
                                                                            <div class="group">
                                                                                <div class="form_label">
                                                                                    <label class="text_uppercase">
                                                                                        <strong>Degree
                                                                                            <span class="required_filed">*</span>
                                                                                        </strong>
                                                                                    </label>
                                                                                </div>
                                                                                <div class="field_holder animated_box">
                                                                                    <input
                                                                                        autocomplete="nope"
                                                                                        type="text"
                                                                                        name="Degree"
                                                                                        class="form_field"
                                                                                        :class="{ 'error': education.degree.$error }"
                                                                                        v-model="education.degree.$model"
                                                                                        placeholder="Degree"
                                                                                    />
                                                                                    <span class="animated_class"></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <hr v-if="(applicant.educationInfo.length - 1) > index" :key="`hr-education-${index}`" />
                                                                </template>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- Previous Employment -->
                                                    <div v-else-if="type === 'employment'" class="main fieldbox">
                                                        <div class="fieldbox">
                                                            <div class="fieldheader">
                                                                <p>
                                                                    <i class="fa fa-info-circle"></i> Previous Employment
                                                                </p>
                                                            </div>
                                                            <div class="fieldcontent">

                                                                <div v-for="(employment, index) in $v.applicant.employmentInfo.$each.$iter" :key="`employments-${index}`" class="form_box employment-container" :class="[ index > 0 ? 'mt-4' : '' ]">

                                                                    <span v-if="index > 0" class="remove-btn" @click="removeEmploymentRecord(employment.$model, index)">
                                                                        <i class="fa fa-times"></i>
                                                                    </span>

                                                                    <div class="form_box_col2">
                                                                        <div class="group">
                                                                            <div class="form_label">
                                                                                <label class="text_uppercase">
                                                                                    <strong>Company
                                                                                        <span class="required_filed">*</span>
                                                                                    </strong>
                                                                                </label>
                                                                            </div>
                                                                            <div class="field_holder animated_box">
                                                                                <input
                                                                                    autocomplete="nope"
                                                                                    type="text"
                                                                                    v-model="employment.company.$model"
                                                                                    :class="{ 'error': employment.company.$error }"
                                                                                    class="form_field"
                                                                                    placeholder="Company"
                                                                                />
                                                                                <span class="animated_class"></span>
                                                                            </div>
                                                                        </div>

                                                                        <div class="group">
                                                                            <div class="form_label">
                                                                                <label class="text_uppercase">
                                                                                    <strong>Phone
                                                                                        <span class="required_filed">*</span>
                                                                                    </strong>
                                                                                </label>
                                                                            </div>
                                                                            <div class="field_holder animated_box">
                                                                                <input
                                                                                    autocomplete="nope"
                                                                                    type="text"
                                                                                    v-model="employment.phone.$model"
                                                                                    :class="{ 'error': employment.phone.$error }"
                                                                                    class="form_field"
                                                                                    placeholder="Phone"
                                                                                />
                                                                                <span class="animated_class"></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="form_box_col2">
                                                                        <div class="group">
                                                                            <div class="form_label">
                                                                                <label class="text_uppercase">
                                                                                    <strong>Address
                                                                                        <span class="required_filed">*</span>
                                                                                    </strong>
                                                                                </label>
                                                                            </div>
                                                                            <div class="field_holder animated_box">
                                                                                <input
                                                                                    autocomplete="nope"
                                                                                    type="text"
                                                                                    v-model="employment.address.$model"
                                                                                    :class="{ 'error': employment.address.$error }"
                                                                                    class="form_field"
                                                                                    placeholder="Address"
                                                                                />
                                                                                <span class="animated_class"></span>
                                                                            </div>
                                                                        </div>

                                                                        <div class="group">
                                                                            <div class="form_label">
                                                                                <label class="text_uppercase">
                                                                                    <strong>Supervisor
                                                                                        <span class="required_filed">*</span>
                                                                                    </strong>
                                                                                </label>
                                                                            </div>
                                                                            <div class="field_holder animated_box">
                                                                                <input
                                                                                    autocomplete="nope"
                                                                                    type="text"
                                                                                    v-model="employment.supervisor.$model"
                                                                                    :class="{ 'error': employment.supervisor.$error }"
                                                                                    class="form_field"
                                                                                    placeholder="Supervisor"
                                                                                />
                                                                                <span class="animated_class"></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="form_box_col2">
                                                                        <div class="group">
                                                                            <div class="form_label">
                                                                                <label class="text_uppercase">
                                                                                    <strong>Job Title
                                                                                        <span class="required_filed">*</span>
                                                                                    </strong>
                                                                                </label>
                                                                            </div>
                                                                            <div class="field_holder animated_box">
                                                                                <input
                                                                                    autocomplete="nope"
                                                                                    type="text"
                                                                                    v-model="employment.jobTitle.$model"
                                                                                    :class="{ 'error': employment.jobTitle.$error }"
                                                                                    class="form_field"
                                                                                    placeholder="Job Title"
                                                                                />
                                                                                <span class="animated_class"></span>
                                                                            </div>
                                                                        </div>

                                                                        <div class="group">
                                                                            <table class="radio no-border" border="0" cellpadding="0" cellspacing="0">
                                                                                <tr>
                                                                                    <td style="width: 50%!important;">
                                                                                        <div class="form_label">
                                                                                            <label class="text_uppercase">
                                                                                                <strong>Starting Salary
                                                                                                    <span class="required_filed">*</span>
                                                                                                </strong>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div class="field_holder animated_box">
                                                                                            <input
                                                                                                autocomplete="nope"
                                                                                                type="text"
                                                                                                v-model="employment.startingSalary.$model"
                                                                                                :class="{ 'error': employment.startingSalary.$error }"
                                                                                                class="form_field"
                                                                                                placeholder="Starting Salary $"
                                                                                            />
                                                                                            <span class="animated_class"></span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td style="width: 50%!important;">
                                                                                        <div class="form_label">
                                                                                            <label class="text_uppercase">
                                                                                                <strong>Ending Salary
                                                                                                    <span class="required_filed">*</span>
                                                                                                </strong>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div class="field_holder animated_box">
                                                                                            <input
                                                                                                autocomplete="nope"
                                                                                                type="text"
                                                                                                v-model="employment.endingSalary.$model"
                                                                                                :class="{ 'error': employment.endingSalary.$error }"
                                                                                                class="form_field"
                                                                                                placeholder="Ending Salary $"
                                                                                            />
                                                                                            <span class="animated_class"></span>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            </table>
                                                                        </div>
                                                                    </div>

                                                                    <div class="form_box_col1">
                                                                        <div class="group">
                                                                            <div class="form_label">
                                                                                <label class="text_uppercase">
                                                                                    <strong>Responsibilities
                                                                                        <span class="required_filed">*</span>
                                                                                    </strong>
                                                                                </label>
                                                                            </div>
                                                                            <div class="field_holder animated_box">
                                                                                <input
                                                                                    autocomplete="nope"
                                                                                    type="text"
                                                                                    v-model="employment.responsibilities.$model"
                                                                                    :class="{ 'error': employment.responsibilities.$error }"
                                                                                    class="form_field"
                                                                                    placeholder="Responsibilities"
                                                                                />
                                                                                <span class="animated_class"></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="form_box_col2">
                                                                        <div class="group">
                                                                            <table class="radio no-border" border="0" cellpadding="0" cellspacing="0">
                                                                                <tr>
                                                                                    <td style="width: 50%!important;">
                                                                                        <div class="form_label">
                                                                                            <label class="text_uppercase">
                                                                                                <strong>From
                                                                                                    <span class="required_filed">*</span>
                                                                                                </strong>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div class="field_holder animated_box">
                                                                                            <datepicker
                                                                                                format="MM/dd/yyyy"
                                                                                                class="form_field Date"
                                                                                                v-model="employment.from.$model"
                                                                                                :class="{ 'error': employment.from.$error }"
                                                                                                placeholder="From"
                                                                                            />
                                                                                            <span class="animated_class"></span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td style="width: 50%!important;">
                                                                                        <div class="form_label">
                                                                                            <label class="text_uppercase">
                                                                                                <strong>To
                                                                                                    <span class="required_filed">*</span>
                                                                                                </strong>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div class="field_holder animated_box">
                                                                                            <datepicker
                                                                                                format="MM/dd/yyyy"
                                                                                                class="form_field Date"
                                                                                                v-model="employment.to.$model"
                                                                                                :class="{ 'error': employment.to.$error }"
                                                                                                placeholder="To"
                                                                                            />
                                                                                            <span class="animated_class"></span>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            </table>
                                                                        </div>

                                                                        <div class="group">
                                                                            <div class="form_label">
                                                                                <label class="text_uppercase">
                                                                                    <strong>Reason for Leaving
                                                                                        <span class="required_filed">*</span>
                                                                                    </strong>
                                                                                </label>
                                                                            </div>
                                                                            <div class="field_holder animated_box">
                                                                                <input
                                                                                    autocomplete="nope"
                                                                                    type="text"
                                                                                    v-model="employment.leavingReason.$model"
                                                                                    :class="{ 'error': employment.leavingReason.$error }"
                                                                                    class="form_field"
                                                                                    placeholder="Reason for Leaving"
                                                                                />
                                                                                <span class="animated_class"></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="form_box_col1">
                                                                        <div class="group">
                                                                            <div class="form_label">
                                                                                <label class="text_uppercase">
                                                                                    <strong>May we contact your previous supervisor for a reference?
                                                                                        <span class="required_filed">*</span>
                                                                                    </strong>
                                                                                </label>
                                                                            </div>
                                                                            <table class="radio" border="0" cellpadding="0" cellspacing="0">
                                                                                <tr>
                                                                                    <td style="width: 50%!important;">
                                                                                        <input
                                                                                            autocomplete="nope"
                                                                                            type="radio"
                                                                                            :class="{ 'form_field error': employment.canContactSupervisor.$error }"
                                                                                            v-model="employment.canContactSupervisor.$model"
                                                                                            name="previous_supervisor"
                                                                                            :value="true"
                                                                                            :id="`${index}-previous_supervisor_yes`"
                                                                                        />
                                                                                        <label :for="`${index}-previous_supervisor_yes`" style="font-weight:normal; color:#000;">YES</label>
                                                                                    </td>
                                                                                    <td style="width: 50%!important;">
                                                                                        <input
                                                                                            autocomplete="nope"
                                                                                            type="radio"
                                                                                            :class="{ 'form_field error': employment.canContactSupervisor.$error }"
                                                                                            v-model="employment.canContactSupervisor.$model"
                                                                                            name="previous_supervisor"
                                                                                            :value="false"
                                                                                            :id="`${index}-previous_supervisor_no`"
                                                                                        >
                                                                                        <label :for="`${index}-previous_supervisor_no`" style="font-weight:normal; color:#000;">NO</label>
                                                                                    </td>
                                                                                </tr>
                                                                            </table>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                                <!--  -->
                                                                <div class="form_box2 secode_box text-center" style="margin-top: 10px;">
                                                                    <div class="inner_form_box">
                                                                        <div class="btn-submit">
                                                                            <button type="button" class="button form_button" style="width: 300px; padding: 15px; margin: 0; height: auto;" @click="applicant.employmentInfo.push({})">Add Previous Employment</button>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="form_box secode_box text-center" style="margin-top: 10px; display: flex; justify-content: space-between;">
                                                        <div class="btn-submit">
                                                            <button v-if="showPrevBtn" type="button" class="button form_button" :disabled="loading" @click="getPreviousPage">PREVIOUS</button>
                                                        </div>
                                                        <div class="btn-submit">
                                                            <button v-if="['military'].includes(type)" type="submit" class="button form_button" style="margin-right: 10px; background: darkorange;" @click="getNextPage">SKIP</button>
                                                            <button v-if="showNextBtn" type="submit" class="button form_button" @click="getNextPage">NEXT</button>
                                                            <button v-else type="submit" class="button form_button" :disabled="loading" @click="handleSubmitForm">SUBMIT</button>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- END FORM -->

                        </div>
                    </div>

                </main>

                <div class="wrapper text-center" style="padding-bottom: 20px;">
                    <div class="animate" data-animate="fadeInUp" data-duration="1.0s"></div>
                    <div class="animate" data-animate="fadeInUp" data-duration="2091ms">
                        <img style="padding: 10px;" src="~@/assets/images/approved.png" alt="">
                        <img style="padding: 10px;" src="~@/assets/images/license.png" alt="">
                    </div>
                    <div class="animate" data-animate="fadeInUp" data-duration="1.0s"></div>
                </div>

                <div class="clearfix"></div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import alert from '@/components/layouts/alert'
import banner from '@/components/layouts/banner'
import { required, requiredIf } from 'vuelidate/lib/validators'
import { getHealthCareInfo, postHealthCareService, deleteHealthCareEmployment } from '@/api/apiCalls'

export default {
    data() {
        return {
            hash: null,
            type: 'position',
            types: [
                'position',
                'military',
                'education',
                'employment'
            ],
            disabledDates: {
                to: new Date(), // Disable all dates up to specific date
            },
            applicant: {
                position: '',
                availableDate: '',
                militaryInfo: {
                    id: '',
                    branch: '',
                    from: '',
                    to: '',
                    dischargeRank: '',
                    dischargeType: '',
                },
                positionInfo: {
                    id: '',
                    haveProfessionalLicense: '',
                    licenseType: '',
                    licenseNumber: '',
                    licenseIsSuspended: '',
                    suspendReason: '',
                    isUSCitizen: '',
                    isUSAuthorized: '',
                    isWorkedBefore: '',
                    workedDate: '',
                    hasMisdemeanor: '',
                    misdemeanorDetails: '',
                    languages: [],
                },
                educationInfo: [{
                    id: '',
                    name: 'High School',
                    address: '',
                    from: '',
                    to: '',
                    graduated: '',
                    degree: '',
                },
                {
                    id: '',
                    name: 'College',
                    address: '',
                    from: '',
                    to: '',
                    graduated: '',
                    degree: '',
                },
                {
                    id: '',
                    name: 'Other',
                    address: '',
                    from: '',
                    to: '',
                    graduated: '',
                    degree: '',
                }],
                employmentInfo: [{
                    id: '',
                    company: '',
                    phone: '',
                    address: '',
                    supervisor: '',
                    jobTitle: '',
                    startingSalary: '',
                    endingSalary: '',
                    responsibilities: '',
                    from: '',
                    to: '',
                    leavingReason: '',
                    canContactSupervisor: '',
                }],
            },
            submitted: false,
            loading: false,
            alertData: {
                showAlert: false,
                alertType: 'success',
                alertText: 'Data Added Successfully!'
            },
        }
    },

    validations: {
        applicant: {
            position: { required },
            availableDate: { required },
            militaryInfo: {
                id: {},
                branch: {},
                from: {},
                to: {},
                dischargeRank: {},
                dischargeType: {},
            },
            positionInfo: {
                id: {},
                haveProfessionalLicense: { required },
                licenseType: {
                    required: requiredIf(model => {
                        return model.haveProfessionalLicense
                    })
                },
                licenseNumber: {
                    required: requiredIf(model => {
                        return model.haveProfessionalLicense
                    })
                },
                licenseIsSuspended: { required },
                suspendReason: {
                    required: requiredIf(model => {
                        return model.licenseIsSuspended
                    })
                },
                isUSCitizen: { required },
                isUSAuthorized: {
                    required: requiredIf(model => {
                        return !model.isUSCitizen
                    })
                },
                isWorkedBefore: { required },
                workedDate: {
                    required: requiredIf(model => {
                        return model.isWorkedBefore
                    })
                },
                hasMisdemeanor: { required },
                misdemeanorDetails: {
                    required: requiredIf(model => {
                        return model.hasMisdemeanor
                    })
                },
                languages: { required },
            },
            educationInfo: {
                $each: {
                    id: {},
                    name: { required },
                    address: { required },
                    from: { required },
                    to: { required },
                    graduated: { required },
                    degree: { required },
                }
            },
            employmentInfo: {
                $each: {
                    id: {},
                    company: { required },
                    phone: { required },
                    address: { required },
                    supervisor: { required },
                    jobTitle: { required },
                    startingSalary: { required },
                    endingSalary: { required },
                    responsibilities: { required },
                    from: { required },
                    to: { required },
                    leavingReason: { required },
                    canContactSupervisor: { required },
                }
            },
        },
    },

    computed: {
        showPrevBtn() {
            return this.type !== 'position'
        },

        showNextBtn() {
            return this.type !== 'employment'
        },

        showSubmitBtn() {
            return this.type === 'employment'
        },
    },

    components: {
        alert,
        appBanner: banner,
    },

    mounted () {
        if (this.$route && this.$route.query && this.$route.query.hash) {
            getHealthCareInfo(this.$route.query.hash).then(({ data }) => {
                this.applicant = data.data
            }).catch(() => {
                this.$router.replace({ name: 'Home' })
            })
        }

        this.hash = this.$route.query.hash || null
        this.type = this.$route.query.type || 'position'
    },

    methods: {

        getPreviousPage() {
            const index = this.types.indexOf(this.type)
            const type = this.types[index - 1]
            this.type = type

            this.$router.replace({
                query: {
                    hash: this.hash,
                    type
                }
            })
        },

        getNextPage() {
            const index = this.types.indexOf(this.type)
            const type = this.types[index + 1]
            this.type = type

            this.$router.replace({
                query: {
                    hash: this.hash,
                    type
                }
            })
        },

        removeEmploymentRecord(item, index) {
            if (item && item.id) {
                this.$swal({
                    text: 'Are you sure, you want to remove this info ?!',
                    type: 'question',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes'
                }).then(({ value }) => {
                    if (value) {
                        deleteHealthCareEmployment(item.id).then(() => {
                            this.applicant.employmentInfo.splice(index, 1)
                        })
                    }
                })
            } else {
                this.applicant.employmentInfo.splice(index, 1)
            }
        },

        handleSubmitForm() {

            // stop here if form is invalid
            this.$v.applicant.$touch()

            // Scroll To Error
            this.$nextTick(() => {
                const element = document.querySelector('.form_field.error')
                if (element) {
                    let domRect = element.getBoundingClientRect()
                    window.scrollTo({
                        left: domRect.left + document.documentElement.scrollLeft,
                        top: domRect.top + (document.documentElement.scrollTop - 100),
                        behavior: 'smooth'
                    })
                }
            })

            if (!this.$v.applicant.$invalid) {
                this.loading = true

                postHealthCareService(this.$route.query.hash, this.applicant).then(() => {
                    this.$router.push({
                        name: 'Confirmation'
                    })
                }).finally(() => {
                    this.loading = false
                })
            }
        }
    },
}
</script>

<style>
body {
    font-family: 'Muli', sans-serif;
}
</style>

<style lang="scss">
/* Progress bar */
:root {
    --blue-color: rgb(51, 85, 170);
}
.progress-bar {
    position: relative;
    display: flex;
    justify-content: space-around;
    counter-reset: step;
    margin-bottom: 30px;
}

.progress-bar::before,
.progress {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 4px;
    width: 100%;
    background-color: #dcdcdc;
    z-index: -1;
}

.progress {
    background-color: var(--blue-color);
    width: 0;
    transition: .5s;
}

.progress-step {
    width: 35px;
    height: 35px;
    background-color: #dcdcdc;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.progress-step::before {
    counter-increment: step;
    content: counter(step);
}

.progress-step::after {
    content: attr(data-title);
    position: absolute;
    top: calc(100% + 0.20rem);
    font-size: 0.85rem;
    color: black !important;
}

.progress-step.active {
    background-color: var(--blue-color);
    color: white;
}

@keyframes animate {
    from {
        transform: scale(1, 0);
        opacity: 0;
    }

    to {
        transform: scale(1, 1);
        opacity: 1;
    }
}
/* End Progress bar */

.employment-container {
    position: relative!important;
    border: 1px solid #eee;
    background: rgba(#eee, 0.3);
    border-radius: 10px;
    padding: 10px;

    &.mt-4 {
        margin-top: 40px!important;
    }

    .remove-btn {
        top: -15px;
        right: 20px;
        width: 30px;
        height: 30px;
        color: #fff;
        border-radius: 50%;
        display: inline-block;
        background: darkred;
        cursor: pointer!important;
        position: absolute!important;

        i.fa {
            width: 30px;
            height: 30px;
            margin: 0 auto;
            line-height: 30px;
            text-align: center;
            display: inline-block;

            &:before {
                left: 0!important;
                right: 0!important;
            }
        }
    }
}
</style>

<style lang="scss" scoped>
.form_head {border-radius: 10px; }
.form_head p.title_head:nth-child(1) { background: #ff3f3f;  margin: 0;  padding: 10px;  color: #fff;  font-weight: bold;  border-top-right-radius: 8px;  border-top-left-radius: 8px;}
.form_head .form_box .form_box_col1 p { margin-bottom: 4px; }
.form_head .form_box { margin: 0; padding: 25px 28px; border: 2px solid #ddd; border-top: none;  border-bottom-right-radius: 8px;  border-bottom-left-radius: 8px;}
.fieldh{text-align: center;}
.amount{
    padding: 10px 90px;
}
#icon {
    position: absolute;
    padding: 10px 39px 10px 10px;
    background: #002948;
    // height: 61px;
    color: #fff;
    font-size: 31px;
    top: 0;
    bottom: 0;
    margin-top: 0!important;
}
.label {text-transform: uppercase;font-weight: bold;font-size: 20px;display: block;background: #1d58b3;padding: 20px 0;text-align: center;color: #fff;margin:5px 0;}
.main.fieldbox { margin-bottom: 30px; }
.fieldbox { margin: 10px 0; }
.fieldheader p { margin: 0; background: #002948; padding: 8px; color: #fff; text-align: center; font-weight: 700; border-top-left-radius: 5px; border-top-right-radius: 10px; font-size:25px;}
.fieldheader p i.fa { margin: 0 auto!important;width: 45px;height: 20px;text-align: center; }
.fieldcontent { padding: 20px; border: 3px solid #002948; border-top: 0; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; }

@import '~@/assets/css/forms.css';
</style>
